@font-face {
  font-family: "Agustina Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Agustina Regular"),
    url("./assets/fonts/Agustina.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Caveat";
  src: local("Caveat"), url("./assets/fonts/Caveat-Medium.ttf") format("truetype");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("woff");
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font: 19px / 23px Montserrat, "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* This will affect the entire page */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scroll thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scroll thumb on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* "auto" or "thin"  */
  scrollbar-color: #888 #f1f1f1; /* thumb and track color */
}

/* Media Query */
@media (max-width: 1380px) {

  html,
  body {
    font-size: rem;
    line-height: normal;
  }
}