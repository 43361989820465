/* Change colors in this file to style to your needs on a global level. 
Certain colors are used for different and multiply types of elements. 
For greater control over colors visit each components .scss file. */

// button colors
$buttonColor: #003300;
$buttonHover: #5d8f4a;
$topButtonHover: #000;

// text colors light theme
$titleColor: #000000;
$textColor: #000000;
$subTitle: #465055;
$cardSubtitle: #666666;
$talkCardSubTitle: #666666;
$blogCardTitleColor: #262626;

// text color dark theme
$textColorDark: #ffffff;

// toggle switch colors
$toggleCheck: #0a0a0a;
$toggleSwitchSliderBG: #ccc;

// githubRepo specific colors
$githubRepoCardLanguageColorBG: #0000ff;
$githubRepoCardColor: rgb(88, 96, 105);
$githubRepoCardRepoCardStatsColor: rgb(106, 115, 125);
$githubRepoCardRepoNameColor: rgb(36, 41, 46);
$githubProfileCardLocationTS: #ffebcd;
$githubProfileCardBorder: #030;

// light background colors
$lightBackground1: #fff;
$lightBackground2: rgb(255, 255, 255);
$lightBackground3: #f5f2f4;
$blogCardContainerColor: #586069;
// dark background colors
$darkBackground: rgb(11, 13, 13);

// light theme box shadows
$lightBoxShadowDark: rgba(0, 0, 0, 0.2);
$lightBoxShadow: rgba(0, 0, 0, 0.1);
$lightBoxShadowDarker: rgba(0, 0, 0, 0.3);

// dark theme box shadows
$darkBoxShadow: #d9dbdf;
$darkBoxShadow2: #ffffff;

// linear gradients
$experienceCardBlurredDivLG: linear-gradient(
  rgba(0, 0, 0, 0.4),
  rgba(0, 0, 0, 0.2)
);
$experienceCardBannerLG: linear-gradient(
  rgba(0, 0, 0, 0.7),
  rgba(0, 0, 0, 0.5)
);

// borders
$progressBarBackground: rgb(243, 239, 239);
$lightBorder1: #0a0a0a;
$educationCardBorder: #a9a7f9;

// hovers
$achievementCertificateCardHoverDark: rgba(255, 255, 255, 0.2);
$blogCardHoverSmallColor: rgba(255, 255, 255, 0.8);
$headerHoverBG: #f4f4f4;
$contactDetailHoverTS: #333;

// misc backgrounds
$progressBarSpanBG:  #003300;
$iconBackground: #333;
$appHeaderBG: #040405;

// misc colors
$skillsColor:  rgb(15, 142, 15);
$appLink: #09d3ac;

// social media icons
$faceBook:  #003300;
$linkedin:  #003300;
$github: #003300;
$gitlab:  #003300;
$google:  #003300;
$twitter: #003300;
$medium:  #003300;
$stackoverflow:  #003300;
$instagram: #003300;
$kaggle:  #003300;
