@import "../../_globalColor";

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
}

.software-skill-inline {
  display: inline-block;
}

.software-skill-inline > i {
  color: $subTitle;
}

.software-skill-inline > i:hover {
  color: $skillsColor;
}
.software-skill-inline > p {
  color: $subTitle;
  font-size: 12px;
}
.software-skill-inline > i:hover ~ p {
  color: $skillsColor;
}
